// @ts-nocheck
/* eslint-disable */
module.exports = {
  languageData: {
  "plurals": function (n, ord) {
    var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
    if (ord) return n10 == 1 && n100 != 11 ? "one" : n10 == 2 && n100 != 12 ? "two" : n10 == 3 && n100 != 13 ? "few" : "other";
    return n == 1 && v0 ? "one" : "other";
  }
},
  messages: {
    "(Name of your language) translation": "(Name of your language) translation",
    "(Note: the email will be sent in English)": "(Note: the email will be sent in English)",
    "(optional)": "(optional)",
    "<0/> makes use of a <1/> and <2/>, which you can review.": "<0/> makes use of a <1/> and <2/>, which you can review.",
    "<0>Free tools for you to fight for a safe and healthy home</0><1>Enter your address to learn more.</1>": "<0>Free tools for you to fight for a safe and healthy home</0><1>Enter your address to learn more.</1>",
    "<0>To</0><1/><2>From</2><3/>": "<0>To</0><1/><2>From</2><3/>",
    "About": "About",
    "About you": "About you",
    "Actions": "Actions",
    "Address": "Address",
    "After this step, you cannot go back to make changes. But don’t worry, we’ll explain what to do next.": "After this step, you cannot go back to make changes. But don\u2019t worry, we\u2019ll explain what to do next.",
    "Alabama": "Alabama",
    "Alas.": "Alas.",
    "Alaska": "Alaska",
    "Apartment needs painting": "Apartment needs painting",
    "Apartment number": "Apartment number",
    "Are you sure you want to log out?": "Are you sure you want to log out?",
    "Are you sure you want to proceed with the following address?": "Are you sure you want to proceed with the following address?",
    "Arizona": "Arizona",
    "Arkansas": "Arkansas",
    "Back": "Back",
    "Back door": "Back door",
    "Baseboards defective": "Baseboards defective",
    "Bathroom": "Bathroom",
    "Bathtub: cracked tub": "Bathtub: cracked tub",
    "Bathtub: drain stoppage": "Bathtub: drain stoppage",
    "Bathtub: faucets not installed": "Bathtub: faucets not installed",
    "Bathtub: faucets not working": "Bathtub: faucets not working",
    "Bathtub: leaky faucet": "Bathtub: leaky faucet",
    "Bathtub: pipes leaking": "Bathtub: pipes leaking",
    "Bedroom": "Bedroom",
    "Broken": "Broken",
    "Broken/defective windows": "Broken/defective windows",
    "Broken/insecure locks": "Broken/insecure locks",
    "Broken/no smoke/Co2 detector": "Broken/no smoke/Co2 detector",
    "Bug infestation": "Bug infestation",
    "Bug infestation (roaches, bedbugs, ... )": "Bug infestation (roaches, bedbugs, ... )",
    "Build my letter": "Build my letter",
    "Build your letter": "Build your letter",
    "Building and safety": "Building and safety",
    "Building exit": "Building exit",
    "Building main entrance": "Building main entrance",
    "Building parking entrance": "Building parking entrance",
    "Buildings in your landlord's portfolio are located in {0, plural, one {one zip code.} other {# zip codes.}}": function (a) {
  return ["Buildings in your landlord's portfolio are located in ", a("0", "plural", {
    one: "one zip code.",
    other: ["#", " zip codes."]
  })];
},
    "Buzzer not working": "Buzzer not working",
    "California": "California",
    "Can't pay rent?": "Can't pay rent?",
    "Cancel": "Cancel",
    "Ceiling falling/fell": "Ceiling falling/fell",
    "Ceiling leaking": "Ceiling leaking",
    "Change": "Change",
    "Change city and state from {cityAndState}": function (a) {
  return ["Change city and state from ", a("cityAndState")];
},
    "City": "City",
    "City and state": "City and state",
    "City/township/borough": "City/township/borough",
    "Click here to learn more about our privacy policy.": "Click here to learn more about our privacy policy.",
    "Cockroaches": "Cockroaches",
    "Colorado": "Colorado",
    "Common areas (parking, hallway, etc.)": "Common areas (parking, hallway, etc.)",
    "Confirm": "Confirm",
    "Confirm your new password": "Confirm your new password",
    "Confirming the address": "Confirming the address",
    "Confirming the city": "Confirming the city",
    "Connecticut": "Connecticut",
    "Contact": "Contact",
    "Contact a lawyer if your landlord retaliates": "Contact a lawyer if your landlord retaliates",
    "Continue": "Continue",
    "Cracked sink": "Cracked sink",
    "Cracked walls": "Cracked walls",
    "Create a password": "Create a password",
    "Date": "Date",
    "Date (optional)": "Date (optional)",
    "Dear <0/>,": "Dear <0/>,",
    "Defective electricity": "Defective electricity",
    "Defective plumbing": "Defective plumbing",
    "Delaware": "Delaware",
    "Developed with <0>Law Help Interactive</0>": "Developed with <0>Law Help Interactive</0>",
    "Dining room": "Dining room",
    "District of Columbia": "District of Columbia",
    "Do you live in {0}, {1}?": function (a) {
  return ["Do you live in ", a("0"), ", ", a("1"), "?"];
},
    "Do you still want to mail to:": "Do you still want to mail to:",
    "Don’t worry, we’ll save your progress so you’ll be able to come back to your last step when you log back in.": "Don\u2019t worry, we\u2019ll save your progress so you\u2019ll be able to come back to your last step when you log back in.",
    "Door lock not working": "Door lock not working",
    "Door not working": "Door not working",
    "Doorbell not working": "Doorbell not working",
    "Drain stoppage": "Drain stoppage",
    "Dryer not working": "Dryer not working",
    "Edit": "Edit",
    "Electric wiring exposed": "Electric wiring exposed",
    "Electricity": "Electricity",
    "Electricity not working": "Electricity not working",
    "Email address": "Email address",
    "English version": "English version",
    "Enter your address to see some recommended actions.": "Enter your address to see some recommended actions.",
    "FAQs": "FAQs",
    "Faqs": "Faqs",
    "Faucets not installed": "Faucets not installed",
    "Faucets not working": "Faucets not working",
    "Faulty doors": "Faulty doors",
    "Floor level": "Floor level",
    "Floor sags": "Floor sags",
    "Florida": "Florida",
    "Frequently Asked Questions": "Frequently Asked Questions",
    "Front door": "Front door",
    "Front door not working": "Front door not working",
    "Fumes/smoke entering apartment": "Fumes/smoke entering apartment",
    "Gas": "Gas",
    "Georgia": "Georgia",
    "Go back": "Go back",
    "Got it": "Got it",
    "Got it, take me there": "Got it, take me there",
    "Habitability (CA)": "Habitability (CA)",
    "Hallway": "Hallway",
    "Harassment (CA)": "Harassment (CA)",
    "Harassment (LA City/TAHO)": "Harassment (LA City/TAHO)",
    "Hawaii": "Hawaii",
    "Health": "Health",
    "Hello world": "Hello world",
    "Help": "Help",
    "Holes": "Holes",
    "Homepage": "Homepage",
    "How do you protect my personal information?": "How do you protect my personal information?",
    "How it works": "How it works",
    "I agree to the <0/> terms and conditions.": "I agree to the <0/> terms and conditions.",
    "I forgot my password": "I forgot my password",
    "I have no apartment number": "I have no apartment number",
    "Idaho": "Idaho",
    "If you didn't receive a code, try checking your email. If it's not in there either, please email <0/>.": "If you didn't receive a code, try checking your email. If it's not in there either, please email <0/>.",
    "If you need to make changes to your name or contact information, please contact <0/>.": "If you need to make changes to your name or contact information, please contact <0/>.",
    "If you write checks or transfer money through your bank to pay your rent, use that name here.": "If you write checks or transfer money through your bank to pay your rent, use that name here.",
    "Illinois": "Illinois",
    "Inadequate water pressure": "Inadequate water pressure",
    "Inadequate/no super service": "Inadequate/no super service",
    "Indiana": "Indiana",
    "Iowa": "Iowa",
    "Is this free?": "Is this free?",
    "It doesn't seem like this property is required to register with HPD. You can learn about the City's registration requirements on <0>HPD's Property Management page</0>.": "It doesn't seem like this property is required to register with HPD. You can learn about the City's registration requirements on <0>HPD's Property Management page</0>.",
    "It’s your first time here!": "It\u2019s your first time here!",
    "JustFix is a registered 501(c)(3) nonprofit organization.": "JustFix is a registered 501(c)(3) nonprofit organization.",
    "Kansas": "Kansas",
    "Kentucky": "Kentucky",
    "Kitchen": "Kitchen",
    "Landlord address": "Landlord address",
    "Landlord name": "Landlord name",
    "Landlord/management company's email": "Landlord/management company's email",
    "Landlord/management company's name": "Landlord/management company's name",
    "Language:": "Language:",
    "Laundry room": "Laundry room",
    "Lead-based paint": "Lead-based paint",
    "Leaky faucet": "Leaky faucet",
    "Learn about why we made this tool, who we are, and who our partners are.": "Learn about why we made this tool, who we are, and who our partners are.",
    "Learn about your rent": "Learn about your rent",
    "Learn more": "Learn more",
    "Learn more about our mission on our website": "Learn more about our mission on our website",
    "Legal first name": "Legal first name",
    "Legal last name": "Legal last name",
    "Legal name": "Legal name",
    "Legally vetted": "Legally vetted",
    "Let's get to know you.": "Let's get to know you.",
    "Let's set you up with a new password, so you can easily login again.": "Let's set you up with a new password, so you can easily login again.",
    "Living room": "Living room",
    "Log in": "Log in",
    "Log out": "Log out",
    "Looks like you're already logged in": "Looks like you're already logged in",
    "Looks like you're not logged in": "Looks like you're not logged in",
    "Looks like you've been here before. Click \"Next\" to be taken to where you left off.": "Looks like you've been here before. Click \"Next\" to be taken to where you left off.",
    "Loose floor": "Loose floor",
    "Louisiana": "Louisiana",
    "Made with NYC ♥ by the team at <0>JustFix</0>": "Made with NYC \u2665 by the team at <0>JustFix</0>",
    "Mail for me": "Mail for me",
    "Mail myself": "Mail myself",
    "Mailing address": "Mailing address",
    "Maine": "Maine",
    "Maryland": "Maryland",
    "Massachusetts": "Massachusetts",
    "Mice": "Mice",
    "Michigan": "Michigan",
    "Minnesota": "Minnesota",
    "Mississippi": "Mississippi",
    "Missouri": "Missouri",
    "Mold": "Mold",
    "Mold on walls": "Mold on walls",
    "Montana": "Montana",
    "More actions": "More actions",
    "Must be at least 14 days from today.": "Must be at least 14 days from today.",
    "Must be at least 8 characters. Can't be all numbers.": "Must be at least 8 characters. Can't be all numbers.",
    "Nebraska": "Nebraska",
    "Nevada": "Nevada",
    "New Hampshire": "New Hampshire",
    "New Jersey": "New Jersey",
    "New Mexico": "New Mexico",
    "New York": "New York",
    "New password": "New password",
    "Next": "Next",
    "No": "No",
    "No cold water": "No cold water",
    "No gas": "No gas",
    "No heat": "No heat",
    "No hot water": "No hot water",
    "No registration found.": "No registration found.",
    "No rent receipts given": "No rent receipts given",
    "No smoke detector": "No smoke detector",
    "North Carolina": "North Carolina",
    "North Dakota": "North Dakota",
    "Not enough": "Not enough",
    "Now we just need your password. This is the same one you’ve used on JustFix or NoRent.": "Now we just need your password. This is the same one you\u2019ve used on JustFix or NoRent.",
    "Ohio": "Ohio",
    "Oklahoma": "Oklahoma",
    "Oops! A network error occurred. Try again later.": "Oops! A network error occurred. Try again later.",
    "Order rent history": "Order rent history",
    "Oregon": "Oregon",
    "Other": "Other",
    "Our Partners": "Our Partners",
    "Our records have shown us a similar address. Would you like to proceed with this address:": "Our records have shown us a similar address. Would you like to proceed with this address:",
    "Our records tell us that this address is invalid.": "Our records tell us that this address is invalid.",
    "Our records tell us that this address is undeliverable.": "Our records tell us that this address is undeliverable.",
    "Outlets not working": "Outlets not working",
    "Painting overdue (3 years)": "Painting overdue (3 years)",
    "Password": "Password",
    "Peeling paint": "Peeling paint",
    "Peeling/flaking paint": "Peeling/flaking paint",
    "Pennsylvania": "Pennsylvania",
    "Phone number": "Phone number",
    "Pipes leaking": "Pipes leaking",
    "Please agree to our terms and conditions": "Please agree to our terms and conditions",
    "Please confirm your password": "Please confirm your password",
    "Please enter your landlord's name and contact information below. You can find this information on your lease and/or rent receipts.": "Please enter your landlord's name and contact information below. You can find this information on your lease and/or rent receipts.",
    "Please use a number that can receive text messages.": "Please use a number that can receive text messages.",
    "Preferred first name": "Preferred first name",
    "Preferred first name (optional)": "Preferred first name (optional)",
    "Privacy Policy": "Privacy Policy",
    "Puerto Rico": "Puerto Rico",
    "Radiators/risers not working": "Radiators/risers not working",
    "Rats": "Rats",
    "Rats/mice": "Rats/mice",
    "Recommended actions": "Recommended actions",
    "Refrigerator not working": "Refrigerator not working",
    "Regards,": "Regards,",
    "Rent receipts incomplete": "Rent receipts incomplete",
    "Request repairs from your landlord": "Request repairs from your landlord",
    "Research your landlord": "Research your landlord",
    "Reset your password": "Reset your password",
    "Results for {0}": function (a) {
  return ["Results for ", a("0")];
},
    "Rhode Island": "Rhode Island",
    "Right to Privacy (CA)": "Right to Privacy (CA)",
    "Rodent infestation (mice, rats, ... )": "Rodent infestation (mice, rats, ... )",
    "Rusty water": "Rusty water",
    "Save": "Save",
    "Search address": "Search address",
    "See more FAQs": "See more FAQs",
    "Select at least one date when you'll be available for repairs": "Select at least one date when you'll be available for repairs",
    "Send a letter of complaint": "Send a letter of complaint",
    "Send code": "Send code",
    "Set up an account": "Set up an account",
    "Set your new password": "Set your new password",
    "Set your password": "Set your password",
    "Share this tool": "Share this tool",
    "Shower: drain stoppage": "Shower: drain stoppage",
    "Shower: leaky shower head": "Shower: leaky shower head",
    "Shower: low water pressure": "Shower: low water pressure",
    "Shower: mold on walls": "Shower: mold on walls",
    "Shower: not working": "Shower: not working",
    "Shower: wall tiles cracked": "Shower: wall tiles cracked",
    "Shower: wall tiles missing": "Shower: wall tiles missing",
    "Sign in": "Sign in",
    "Sign out": "Sign out",
    "Sign up or log in to your account to access our tool.": "Sign up or log in to your account to access our tool.",
    "Signed,": "Signed,",
    "Sink: cracked sink": "Sink: cracked sink",
    "Sink: drain stoppage": "Sink: drain stoppage",
    "Sink: faucets not installed": "Sink: faucets not installed",
    "Sink: faucets not working": "Sink: faucets not working",
    "Sink: leaky faucet": "Sink: leaky faucet",
    "Sink: low water pressure": "Sink: low water pressure",
    "Sink: pipes leaking": "Sink: pipes leaking",
    "Smoke detector not working": "Smoke detector not working",
    "Smoke or carbon monoxide detector": "Smoke or carbon monoxide detector",
    "Sorry, the page you are looking for doesn't seem to exist.": "Sorry, the page you are looking for doesn't seem to exist.",
    "Sorry, we don't recognize the address you entered.": "Sorry, we don't recognize the address you entered.",
    "South Carolina": "South Carolina",
    "South Dakota": "South Dakota",
    "Start": "Start",
    "Start a legal case for repairs and/or harassment": "Start a legal case for repairs and/or harassment",
    "State": "State",
    "Step {0} of {1}": function (a) {
  return ["Step ", a("0"), " of ", a("1")];
},
    "Stove not working": "Stove not working",
    "Strategic Actions for a Just Economy": "Strategic Actions for a Just Economy",
    "Street address (include unit/suite/floor/apt #)": "Street address (include unit/suite/floor/apt #)",
    "Subject: {0}": function (a) {
  return ["Subject: ", a("0")];
},
    "Take action": "Take action",
    "Tennessee": "Tennessee",
    "Terms of Use": "Terms of Use",
    "Texas": "Texas",
    "The majority of your landlord's properties are concentrated in {0}.": function (a) {
  return ["The majority of your landlord's properties are concentrated in ", a("0"), "."];
},
    "The name used in letters to your landlord and legal documents": "The name used in letters to your landlord and legal documents",
    "The name you'd like to be called.": "The name you'd like to be called.",
    "The webpage that you want to access is only available in English.": "The webpage that you want to access is only available in English.",
    "There {0, plural, one {is one unit} other {are # units}} in your building.": function (a) {
  return ["There ", a("0", "plural", {
    one: "is one unit",
    other: ["are ", "#", " units"]
  }), " in your building."];
},
    "Think your apartment may be rent-stabilized? Request its official records.": "Think your apartment may be rent-stabilized? Request its official records.",
    "This building is owned by the <0>NYC Housing Authority (NYCHA)</0>.": "This building is owned by the <0>NYC Housing Authority (NYCHA)</0>.",
    "This information seems wrong. Can I change it?": "This information seems wrong. Can I change it?",
    "This is optional.": "This is optional.",
    "This is your landlord’s information as registered with the <0>NYC Department of Housing and Preservation (HPD)</0>. This may be different than where you send your rent checks.": "This is your landlord\u2019s information as registered with the <0>NYC Department of Housing and Preservation (HPD)</0>. This may be different than where you send your rent checks.",
    "To begin the password reset process, we'll text you a verification code.": "To begin the password reset process, we'll text you a verification code.",
    "To:": "To:",
    "Toilet leaking": "Toilet leaking",
    "Toilet not working": "Toilet not working",
    "Trash cans": "Trash cans",
    "USPS Tracking #:": "USPS Tracking #:",
    "Unit/apt/lot/suite number": "Unit/apt/lot/suite number",
    "Unrecognized address": "Unrecognized address",
    "Unsafe/broken stairs and/or railing": "Unsafe/broken stairs and/or railing",
    "Used for logging in to your account": "Used for logging in to your account",
    "Utah": "Utah",
    "Utilities": "Utilities",
    "Utilities shut off (water, gas, ... )": "Utilities shut off (water, gas, ... )",
    "Vacate order issued": "Vacate order issued",
    "Verification code": "Verification code",
    "Verify your phone number": "Verify your phone number",
    "Vermont": "Vermont",
    "Virginia": "Virginia",
    "Visit Who Owns What": "Visit Who Owns What",
    "Washing machine not working": "Washing machine not working",
    "Washington": "Washington",
    "Water": "Water",
    "Water damage": "Water damage",
    "Water leak": "Water leak",
    "We've just sent you a text message containing a verification code. Please enter it below.": "We've just sent you a text message containing a verification code. Please enter it below.",
    "Weak electrical current": "Weak electrical current",
    "Welcome back!": "Welcome back!",
    "West Virginia": "West Virginia",
    "What contact information do you have for your landlord or building management? <0>We recommend choosing both if you have them.</0>": "What contact information do you have for your landlord or building management? <0>We recommend choosing both if you have them.</0>",
    "What happens next?": "What happens next?",
    "What is your borough?": "What is your borough?",
    "Where do I find this information?": "Where do I find this information?",
    "Where do you live?": "Where do you live?",
    "Where we will send you your documents.": "Where we will send you your documents.",
    "Whether it's your first time here, or you're a returning user, let's start with your number.": "Whether it's your first time here, or you're a returning user, let's start with your number.",
    "Who we are": "Who we are",
    "Why do I need to create an account?": "Why do I need to create an account?",
    "Why do you need this information?": "Why do you need this information?",
    "Why we made this": "Why we made this",
    "Window frame defective": "Window frame defective",
    "Window glass broken": "Window glass broken",
    "Window guards missing": "Window guards missing",
    "Wisconsin": "Wisconsin",
    "Wyoming": "Wyoming",
    "Yes": "Yes",
    "Yes, Sign Out": "Yes, Sign Out",
    "You already have an account": "You already have an account",
    "You can also track the delivery of your letter using USPS Tracking:": "You can also track the delivery of your letter using USPS Tracking:",
    "You can contact Strategic Actions for a Just Economy (SAJE) - a 501c3 non-profit organization in South Los Angeles.": "You can contact Strategic Actions for a Just Economy (SAJE) - a 501c3 non-profit organization in South Los Angeles.",
    "You have chosen to overwrite the landlord recommended by JustFix. Please provide your own details below, or <0>use the recommended landlord \"{0}\"</0>.": function (a) {
  return ["You have chosen to overwrite the landlord recommended by JustFix. Please provide your own details below, or <0>use the recommended landlord \"", a("0"), "\"</0>."];
},
    "Your apartment may be rent stabilized.": "Your apartment may be rent stabilized.",
    "Your building had {0, plural, one {one rent stabilized unit} other {# rent stabilized units}} in {1}.": function (a) {
  return ["Your building had ", a("0", "plural", {
    one: "one rent stabilized unit",
    other: ["#", " rent stabilized units"]
  }), " in ", a("1"), "."];
},
    "Your building was built in {0} or earlier.": function (a) {
  return ["Your building was built in ", a("0"), " or earlier."];
},
    "Your email address": "Your email address",
    "Your landlord is associated with {0, plural, one {one building} other {# buildings}} and {1, plural, one {one unit.} other {# units.}}": function (a) {
  return ["Your landlord is associated with ", a("0", "plural", {
    one: "one building",
    other: ["#", " buildings"]
  }), " and ", a("1", "plural", {
    one: "one unit.",
    other: ["#", " units."]
  })];
},
    "Your landlord is associated with {buildings, plural, one {one building} other {# buildings}}.": function (a) {
  return ["Your landlord is associated with ", a("buildings", "plural", {
    one: "one building",
    other: ["#", " buildings"]
  }), "."];
},
    "Your landlord may be breaking the law!": "Your landlord may be breaking the law!",
    "Your landlord might own other buildings, too.": "Your landlord might own other buildings, too.",
    "Your landlord or management company's address": "Your landlord or management company's address",
    "Your landlord or management company's email": "Your landlord or management company's email",
    "Your landlord or management company's information": "Your landlord or management company's information",
    "Your letter was sent on {0}.": function (a) {
  return ["Your letter was sent on ", a("0"), "."];
},
    "Your phone number": "Your phone number",
    "Your privacy is very important to us": "Your privacy is very important to us",
    "Your privacy is very important to us. Everything on JustFix is secure.": "Your privacy is very important to us. Everything on JustFix is secure.",
    "Your residence": "Your residence",
    "Zip code": "Zip code",
    "justfix.DdoMayNeedHpdRegistration": "It looks like this building may require registration with HPD. Landlords who don't properly register their properties incur fines and also cannot bring tenants to court for nonpayment of rent. You can find more information on <0>HPD's Property Management page</0>",
    "justfix.commonWarningAboutChangingLandlordDetails1": "If you receive rent-related documents from a different address, or if your landlord has instructed you to use a different address for correspondence, you can <0>provide your own details.</0> But, only use a different address if you are absolutely sure it is correct\u2014it is safer to use the official address your landlord provided to the city.",
    "justfix.ddoEhpaDeactivatedMessage": "Since June 2021, Housing Court has blocked tenants from suing their landlord through JustFix\u2019s HP Action Tool. To learn how to file an HP Action now, read our <0>Medium article</0>. To learn more about HP Actions and find a list of legal providers, visit <1>Housing Court Answers</1>.",
    "justfix.ddoLocCovidMessage": "Landlord not responding? You can take action for free to request repairs!",
    "justfix.howIsPersonalInfoProtectedV2": "Everything on JustFix is secure. We don\u2019t use your personal information for profit or sell it to third parties. See our <0/>.",
    "justfix.legalDisclaimer": function (a) {
  return ["Disclaimer: The information in ", a("website"), " does not constitute legal advice and must not be used as a substitute for the advice of a lawyer qualified to give advice on legal issues pertaining to housing. We can help direct you to free legal services if necessary."];
},
    "justfix.privacyInfoModalTextV2": "<0>Your privacy is very important to us. Here are some important things to know:</0><1><2>Your personal information is secure.</2><3>We don\u2019t use your personal information for profit or sell it to third parties.</3><4>We use your address to find information about your landlord and your building.</4></1><5>Our Privacy Policy enables sharing anonymized data with approved tenant advocacy organizations exclusively to help further our tenants rights mission. The Privacy Policy contains information regarding what data we collect, how we use it, and the choices you have regarding your personal information. If you\u2019d like to read more, please review our full <6/> and <7/>.</5>",
    "justfix.whyIsAccountNeeded": "An account allows you to securely create, send and access letters that help you exercise your tenant rights.",
    "justfix.whyIsPhoneNumberNeeded": "We\u2019ll use this information to either:<0><1>Log you into your existing account</1><2>Match with a pre-existing account </2><3>Sign you up for a new account.</3></0><4/>An account will allow you to return to our tools at any point in the process without having to start from the beginning, download any documents you complete, and be notified of relevant changes to policies that affect you.",
    "norent.sajeBlockQuote": "Since 1996 SAJE has been a force for economic justice in our community focusing on tenant rights, healthy housing, and equitable development. SAJE believes that the fate of city neighborhoods should be decided by those who dwell there, and convenes with other organizations to ensure this occurs in a manner that is fair, replicable, and sustainable. Housing is a human right.",
    "norent.sajePhoneCalls": "Strategic Actions for a Just Economy (SAJE) is available for phone calls at (213) 745-9961, Monday-Friday from 10:00am-6:00pm.",
    "{0} (in English)": function (a) {
  return [a("0"), " (in English)"];
},
    "{remaining, plural, one {1 character remaining} other {# characters remaining}}": function (a) {
  return [a("remaining", "plural", {
    one: "1 character remaining",
    other: ["#", " characters remaining"]
  })];
},
  },
};